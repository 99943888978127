<template>
  <div>
    <v-dialog v-model="passwordDialog" max-width="400">
      <v-card>
        <v-card-title>Şifre Değiştir</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="password"
            :append-icon="show1 ? icons.mdiEye : icons.mdiEyeOff"
            :type="show1 ? 'text' : 'password'"
            label="Yeni Şifre"
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn block x-large color="error" class="text-capitalize" @click="passwordChange"> Şifreyi Değiştir </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col>
        <div id="user-list">
          <!-- app drawer -->

          <user-list-add-new
            v-model="isAddNewUserSidebarActive"
            :status-options="statusOptions"
            :customer-group-options="customerGroupOptions"
            :pricelist-options="pricelistOptions"
            :role-options="roleOptions"
            :user-item="userItem"
            :user-type="userType"
            @refetch-data="fetchUsers"
          ></user-list-add-new>

          <user-list-filter
            v-model="isFilterUserSidebarActive"
            :status-options="statusOptions"
            :customer-group-options="customerGroupOptions"
            :pricelist-options="pricelistOptions"
            :role-options="roleOptions"
            @filter-data="filterData"
          >
          </user-list-filter>

          <!-- list filters -->

          <v-card
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
          >
            <v-toolbar
              v-if="!$vuetify.breakpoint.smAndDown"
              :color="$vuetify.breakpoint.mdAndDown ? (isDark ? '#3b355a' : 'transparent') : 'transparent'"
              flat
              :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
            >
              <v-toolbar-title class="text-h6 font-weight-medium">
                <v-icon large left>
                  {{ icons.mdiAccountSupervisor }}
                </v-icon>
                Kullanıcılar
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <!-- <v-btn
              plain
              text
              small
              @click="mailSend"
            >
              <v-icon left>
                {{ icons.mdiSend }}
              </v-icon> E-Posta Gönder
            </v-btn> -->
              <v-btn plain text small @click="mailSend">
                <v-icon left>
                  {{ icons.mdiDownload }}
                </v-icon>
                İndir
              </v-btn>
              <v-btn plain text small @click.stop="isFilterUserSidebarActive = !isFilterUserSidebarActive">
                <v-badge v-if="isFilterNumber" overlap color="tertiary" dot offset-x="15" offset-y="5">
                  <v-icon left>
                    {{ icons.mdiFilter }}
                  </v-icon>
                </v-badge>
                <v-icon v-else left>
                  {{ icons.mdiFilter }}
                </v-icon>
                Filitre
              </v-btn>
              <v-btn
                class="text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 white--text"
                depressed
                color="secondary"
                x-large
                @click="userAdd()"
              >
                <span>Kullanıcı Ekle</span>
              </v-btn>
            </v-toolbar>
            <v-expansion-panels v-else v-model="panel" flat tile>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-body-2 font-weight-medium">
                    <v-icon left>
                      {{ icons.mdiAccountSupervisor }}
                    </v-icon>
                    Kullanıcılar
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0">
                  <v-divider></v-divider>
                  <v-list class="pa-0" color="grey">
                    <v-list-item @click="userAdd()">
                      <v-list-item-content>
                        <v-list-item-title>Kullanıcı Ekle</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon>{{ icons.mdiPlusThick }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click.stop="isFilterUserSidebarActive = !isFilterUserSidebarActive">
                      <v-list-item-content>
                        <v-list-item-title>Filitre</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon :color="isFilterNumber ? 'tertiary' : null">
                          {{ icons.mdiFilter }}
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider></v-divider>
                    <v-list-item @click.stop="isFilterUserSidebarActive = !isFilterUserSidebarActive">
                      <v-list-item-content>
                        <v-list-item-title>İndir</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon>
                          {{ icons.mdiDownload }}
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- table -->
            <v-data-table
              v-model="selectedRows"
              :headers="tableColumns"
              :items="userListTable"
              :options.sync="options"
              :items-per-page="15"
              fixed-header
              :height="dataTableHeight"
              :server-items-length="totalUserListTable"
              :loading="loading"
            >
              <!-- Text -->
              <template #[`item.uid`]="{ item }">
                <div class="text-caption">
                  {{ item.uid }}
                </div>
              </template>

              <template #[`item.adsoyad`]="{ item }">
                <router-link
                  :to="{ name: 'user-view', params: { id: item.uid } }"
                  class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ item.adsoyad }}
                </router-link>
              </template>

              <template #[`item.username`]="{ item }">
                <router-link
                  :to="{ name: 'user-view', params: { id: item.uid } }"
                  class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ item.username }}
                </router-link>
              </template>

              <template #[`item.aktif`]="{ item }">
                <v-chip :color="resolveUserStatusVariant(item.aktif)" link outlined small>
                  {{ statusFind(item.aktif, 'title') }}
                </v-chip>
              </template>
              <template #[`item.son_giris`]="{ item }">
                <div v-if="item.son_giris === null"></div>
                <timeago v-else class="text-caption" :datetime="item.son_giris"></timeago>
              </template>
              <template #[`item.kayit_tarihi`]="{ item }">
                <div class="text-caption">
                  {{ item.kayit_tarihi | moment('Do MMMM YYYY') }}
                </div>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-tooltip bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      color="nightDark"
                      x-small
                      class="rounded-r-0 rounded-l-xl"
                      v-bind="attrs"
                      @click="userEdit(item)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiSquareEditOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Düzenle</span>
                </v-tooltip>
                <v-tooltip bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      color="nightDark"
                      x-small
                      class="rounded-l-0 rounded-r-xl"
                      v-bind="attrs"
                      @click="userPassword(item)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiKey }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Şifre Değiştir</span>
                </v-tooltip>
                <v-tooltip bottom color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed color="error" x-small plain v-bind="attrs" @click="userDelete(item)" v-on="on">
                      <v-icon small>
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Sil</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { avatarText } from '@core/utils/filter'
import { convertArrayToObject, postData } from '@utils'

import store from '@/store'
import {
  mdiAccount,
  mdiAccountPlus,
  mdiAccountSupervisor,
  mdiArrowLeft,
  mdiDelete,
  mdiDotsHorizontal,
  mdiDownload,
  mdiExportVariant,
  mdiEye,
  mdiEyeOff,
  mdiFileExcelBox,
  mdiFilePdfBox,
  mdiFilter,
  mdiFilterOff,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiKey,
  mdiMagnify,
  mdiMessage,
  mdiPencil,
  mdiPlus,
  mdiPlusThick,
  mdiSend,
  mdiShare,
  mdiShield,
  mdiShieldHalfFull,
  mdiSquareEditOutline,
  mdiWeatherNight,
  mdiWeatherSunny,
} from '@mdi/js'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import Vue from 'vue'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import UserListFilter from './UserListFilter.vue'
import useUsersList from './useUsersList'

export default {
  components: {
    UserListAddNew,
    UserListFilter,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const { isDark } = useAppConfig()

    // this won't work because we are passing a number to watch()

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      }
    })

    onMounted(() => {
      // eslint-disable-next-line no-use-before-define
      fetchCustomerGroup()
      // eslint-disable-next-line no-use-before-define
      fetchPricelist()
      // eslint-disable-next-line no-use-before-define
      fetchRole()
    })

    const {
      userListTable,
      tableColumns,
      searchQuery,
      customerGroupFilter,
      cariKodFilter,
      nameFilter,
      usernameFilter,
      emailFilter,
      customerFilter,
      updatedFilter,
      planFilter,
      idFilter,
      statusFilter,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      selectedRows,
      customerGroupOptions,
      pricelistOptions,
      roleOptions,
      fetchUsers,
      fetchCustomerGroup,
      fetchPricelist,
      fetchRole,
      resolveUserRoleVariant,
      statusOptions,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
    } = useUsersList()

    const isAddNewUserSidebarActive = ref(false)
    const isFilterNumber = ref(0)
    const isFilterUserSidebarActive = ref(false)

    const statusFind = (value, select) => statusOptions.find(item => item.value === value)[select]

    const userType = ref('add')
    const userItem = ref({})

    const userEdit = item => {
      userItem.value = item
      userType.value = 'edit'
      isAddNewUserSidebarActive.value = true
    }
    const userAdd = () => {
      userItem.value = {}
      userType.value = 'add'
      isAddNewUserSidebarActive.value = true
    }
    const mailSend = async () => {
      const { value: email } = await Vue.swal({
        icon: 'info',
        title: 'E-Posta Gönder',
        text: 'Göndermek istediğiniz e-posta adresini yazın',
        input: 'email',
        // eslint-disable-next-line consistent-return
        inputValidator: value => {
          if (!value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            return 'Geçersiz e-posta adresi'
          }
        },
      })

      if (email) {
        Vue.swal({ icon: 'success', text: 'E-Posta gönderildi' })
      }
    }

    const userDelete = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Silme işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('app-user/deleteUser', {
              method: 'deleteMember',
              id: item.uid,
            })
            .then(() => {
              Vue.swal({
                title: 'Silindi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                timerProgressBar: true,
                showConfirmButton: false,
              })
              fetchUsers()
            })
        }
      })
    }

    const usersDelete = () => {
      const IDs = []
      selectedRows.value.forEach(item => {
        IDs.push(item.id)
      })

      Vue.swal({
        title: 'Emin misin?',
        text: 'Toplu silme işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          Vue.swal({
            title: 'Silindi',
            text: 'İşlem başarılı',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          })
          selectedRows.value = []
          fetchUsers()
        }
      })
    }

    const usersRole = async () => {
      const IDs = []
      selectedRows.value.forEach(item => {
        IDs.push(item.id)
      })
      const inputOptions = convertArrayToObject(customerGroupOptions.value, 'value', 'title')
      const { value: role } = await Vue.swal({
        icon: 'question',
        title: 'Rol Değiştir',
        text: 'Toplu rol değiştirme değeri seçin',
        input: 'select',
        inputOptions,
        inputPlaceholder: 'Rol Seçin',
        showCancelButton: true,
      })

      if (role) {
        Vue.swal({
          title: 'Değiştirildi',
          text: 'İşlem başarılı',
          icon: 'success',
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        })
        selectedRows.value = []
        fetchUsers()
      }
    }

    const usersStatus = async () => {
      const IDs = []
      selectedRows.value.forEach(item => {
        IDs.push(item.id)
      })

      const inputOptions = convertArrayToObject(statusOptions, 'value', 'title')
      const { value: role } = await Vue.swal({
        icon: 'question',
        title: 'Durum Değiştir',
        text: 'Toplu durum değiştirme değeri seçin',
        input: 'select',
        inputOptions,
        inputPlaceholder: 'Durum Seçin',
        showCancelButton: true,
      })

      if (role) {
        Vue.swal({
          title: 'Değiştirildi',
          text: 'İşlem başarılı',
          icon: 'success',
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        })
        selectedRows.value = []
        fetchUsers()
      }
    }

    const filterData = item => {
      idFilter.value = item.uid || null
      statusFilter.value = item.aktif || null
      customerGroupFilter.value = item.cust_group || null
      cariKodFilter.value = item.cari_kod || null
      nameFilter.value = item.adsoyad || null
      usernameFilter.value = item.username || null
      emailFilter.value = item.email || null
      updatedFilter.value = item.updated || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i
    }

    const passwordDialog = ref(false)
    const show1 = ref(false)
    const password = ref('')
    const passwordID = ref('')
    const userPassword = val => {
      passwordDialog.value = true
      passwordID.value = val.uid
    }
    const passwordChange = uid => {
      postData({
        method: 'resetMemberPassByAdmin',
        newpass: password.value,
        uid: passwordID.value,
      }).then(response => {
        if (response.error === 0) {
          Vue.swal({
            text: response.msg,
            title: 'İşlem başarılı',
            icon: 'success',
            timer: 1500,
            background: '#56CA00',
            timerProgressBar: true,
            showConfirmButton: false,
          })
          passwordDialog.value = false
          passwordID.value = ''
          password.value = ''
        } else {
          Vue.swal({
            title: 'Hata',
            html: response.msg,
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
          password.value = ''
        }
      })
    }

    return {
      userPassword,
      passwordDialog,
      passwordChange,
      show1,
      password,
      mailSend,
      isFilterNumber,
      filterData,
      isDark,
      userListTable,
      tableColumns,
      searchQuery,
      customerGroupFilter,
      cariKodFilter,
      nameFilter,
      usernameFilter,
      emailFilter,
      customerFilter,
      updatedFilter,
      planFilter,
      idFilter,
      statusFilter,
      totalUserListTable,
      customerGroupOptions,
      pricelistOptions,
      roleOptions,
      statusOptions,
      loading,
      options,
      userTotalLocal,
      isAddNewUserSidebarActive,
      isFilterUserSidebarActive,
      selectedRows,
      statusFind,
      avatarText,
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      fetchUsers,
      fetchCustomerGroup,
      fetchPricelist,
      fetchRole,
      userDelete,
      usersDelete,
      usersRole,
      usersStatus,
      userItem,
      userType,
      userEdit,
      userAdd,

      // icons
      icons: {
        mdiDotsHorizontal,
        mdiFileExcelBox,
        mdiFilePdfBox,
        mdiMessage,
        mdiWeatherNight,
        mdiWeatherSunny,
        mdiSquareEditOutline,
        mdiPencil,
        mdiAccountPlus,
        mdiDelete,
        mdiAccount,
        mdiPlus,
        mdiExportVariant,
        mdiAccountSupervisor,
        mdiKey,
        mdiMagnify,
        mdiShare,
        mdiFilter,
        mdiFullscreen,
        mdiFullscreenExit,
        mdiShield,
        mdiSend,
        mdiArrowLeft,
        mdiShieldHalfFull,
        mdiFilterOff,
        mdiPlusThick,
        mdiDownload,
        mdiEye,
        mdiEyeOff,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
